import { useLocation, Outlet, Navigate } from 'react-router-dom'
import useSessionStorage from './useSessionStorage'

const RequireAuth = () => {
  const { getIdentity } = useSessionStorage()
  const location = useLocation()
  const user = getIdentity()

  return user?.id ? (
    <Outlet></Outlet>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace></Navigate>
  )
}

export default RequireAuth
