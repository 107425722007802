const institutionKey = 'institution_info'
const identityKey = 'user_identity'

const useSessionStorage = () => {
  const setInstitution = (institution) => {
    sessionStorage.setItem(institutionKey, JSON.stringify(institution))
  }

  const getInstitution = () => {
    const info = sessionStorage.getItem(institutionKey)
    return info ? JSON.parse(info) : {}
  }

  const setIdentity = (identity) => {
    sessionStorage.setItem(identityKey, JSON.stringify(identity))
  }

  const getIdentity = () => {
    const user = sessionStorage.getItem(identityKey)
    return user ? JSON.parse(user) : {}
  }

  return { setInstitution, getInstitution, setIdentity, getIdentity }
}

export default useSessionStorage
